import { Alert, AlertIcon } from "@chakra-ui/react"

export interface AlertStatus { status: "info" | "warning" | "success" | "error" | "loading" | undefined, message?: string }

const getAlertText = ({ status, message }: AlertStatus) => {
    switch (status) {
        case "error":
            return message || "There was a problem completing your operation"
        case "success":
            return message || "Data uploaded to the server. Fire on!"
        case "loading":
            return message || "Working on it! Please wait..."
        default:
            return message || "Operation Completed SUccessfully";
    }
}

export const AlterBox = ({ status, message }: AlertStatus) => {
    return <Alert status={status}>
        <AlertIcon />
        {getAlertText({ status, message })}
    </Alert>
}