import { Box, BoxProps, CloseButton, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { NavItem } from './NavItem';
import { IconType } from 'react-icons';
import { FiHome, FiPlusCircle, FiList, FiUser } from 'react-icons/fi';
import { Logo } from '../../Logo';
import { PERMISSIONS } from '../../../auth/constants';
import { useCurrentUser } from '../../../auth/hooks/useCurrentUser';

interface DashboardSidebarProps extends BoxProps {
  onClose: () => void;
}

interface LinkItemProps {
  name: string;
  icon: IconType;
  href: string;
  permission: string;
  isAdminOnly?: boolean
}

const LinkItems: Array<LinkItemProps> = [
  { name: 'Dashboard', icon: FiHome, href: '/', permission: "all" },
  { name: 'Add Inventory', icon: FiPlusCircle, href: '/add-inventory', permission: PERMISSIONS.INVENTORY },
  { name: 'View Inventory', icon: FiList, href: '/view-inventory', permission: PERMISSIONS.INVENTORY },
  { name: 'Add User', icon: FiUser, href: '/add-user', permission: "all", isAdminOnly: true }
];

export const DashboardSidebar = ({ onClose, ...rest }: DashboardSidebarProps) => {
  const { isAdmin, permissions, user } = useCurrentUser()
  const filteredLinkItems = LinkItems.filter(item => (permissions.includes(item.permission) || isAdmin || item.permission === "all") && item.isAdminOnly ? isAdmin : true);
  return (
    <Box
      bg={useColorModeValue('white', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Box w="100%" display="flex" alignItems="center" justifyContent="center">
          <Logo height={50} />
        </Box>
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      <Box m="10px 0px" p="10px" w="100%" bg="gray.100" textAlign="center">
        <Text>Logged in as: {user?.name}</Text>
      </Box>
      {filteredLinkItems.map((link) => (
        <NavItem key={link.name} href={link.href} icon={link.icon}>
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
};
