import { Flex } from "@chakra-ui/react";
import { AttendanceKioskView } from "../components/AttendanceKioskView/AttendanceKioskView";

export const AttendanceKiosk = () => {
  return (
    <Flex w="100vw" h="100vh" bgColor="#CFDEBD" overflow="hidden">
      <AttendanceKioskView />
    </Flex>
  );
};
