import { PERMISSIONS } from "../../auth/constants"
import { PermissionsGateway } from "../../auth/pages/PermissionsGateway"
import { DashboardNavigationLayout } from "../../common/Layouts/DashboardNavigationLayout/DashboardNavigationLayout"
import { AddItemizedInventoryView } from "../components/AddItemizedInventory/AddItemizedInventoryView"
import { AddInventoryAuthGateway } from "./AddInventoryAuthGateway"

export const AddItemizedInventory = () => {
    return <>
        <AddInventoryAuthGateway>

            <PermissionsGateway requiredPermissions={[PERMISSIONS.INVENTORY]}>
                <DashboardNavigationLayout>
                    <AddItemizedInventoryView />
                </DashboardNavigationLayout>
            </PermissionsGateway>
        </AddInventoryAuthGateway>
    </>
}