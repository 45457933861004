import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Text,
    useToast,
    Select,
} from '@chakra-ui/react';
import { useState } from 'react';
import { FORM_STYLE } from '../../../common/constants/styles';
import { AlterBox } from '../../../common/components/AlertBox';
import { useAddUserMutation } from '../../../auth/usersApi';
import { PERMISSIONS_LIST, USER_ROLES_LIST } from '../../../auth/constants';
import MultiSelect from 'react-select';

export const AddUserForm = () => {
    const [name, setName] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [role, setRole] = useState("");
    const [permissions, setPermissions] = useState<any>([]);
    const toast = useToast()
    const handleSubmit = () => {
        if (!name || !password) {
            return toast({
                title: 'Please add missing fields.',
                description: `Field(s) Missing: ${!name && "Name, "}${!password && "Unit, "} `,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
        addUser({ name, username, password, role, permissions: permissions.map((obj: any) => obj.value).join("|") })
    };
    const [addUser, { isSuccess, isLoading, isError }] = useAddUserMutation()
    const isProcessing = isSuccess || isLoading || isError
    const permissionOptions = PERMISSIONS_LIST.map(permissionValue => ({ value: permissionValue, label: permissionValue.charAt(0).toUpperCase() + permissionValue.slice(1) }))

    return (
        <Box style={{ ...FORM_STYLE }}>
            <Stack spacing={4}>
                <FormControl>
                    <FormLabel>
                        Name <Text as="span">*</Text>
                    </FormLabel>
                    <Input onChange={e => setName(e.target.value)} isRequired type="text" />
                </FormControl>
                <FormControl>
                    <FormLabel>
                        Username <Text as="span">*</Text>
                    </FormLabel>
                    <Input onChange={e => setUsername(e.target.value)} isRequired type="text" />
                </FormControl>
                <FormControl>
                    <FormLabel>
                        Password <Text as="span">*</Text>
                    </FormLabel>
                    <Input onChange={e => setPassword(e.target.value)} isRequired type="text" />
                </FormControl>
                <label>Role:</label>
                <Select placeholder='Select option' onChange={e => setRole(e.target.value)}>
                    {USER_ROLES_LIST.map(userRole => <option value={userRole}>{userRole}</option>)}
                </Select>

                <label>Permissions:</label>
                <MultiSelect
                    isMulti
                    defaultValue={permissions}
                    onChange={setPermissions}
                    options={permissionOptions}
                />
                <Button disabled={isProcessing} size="lg" onClick={handleSubmit}>
                    Add User
                </Button>
                {isProcessing && <AlterBox status={isSuccess ? "success" : isError ? "error" : "loading"} message={isSuccess ? "User Added Successfully" : isError ? "Error Adding user." : "Loading..."} />}
            </Stack>
        </Box>
    );
};
