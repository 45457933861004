import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    Textarea,
    Stack,
    Text,
    Alert,
    AlertIcon,
    useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import { FORM_STYLE } from '../../../common/constants/styles';
import { useAddInventoryMutation, useAddItemizedInventoryMutation, useGetInventoryItemQuery } from '../../inventoryApi';
import { AlterBox } from '../../../common/components/AlertBox';
import { Loading } from '../../../common/Loading';

export const AddItemizedInventoryItemForm = ({ inventoryId }: { inventoryId: string }) => {
    const [totalQuantity, setTotalQuantity] = useState("");
    const [notes, setNotes] = useState("");
    const [averageCostPerUnit, setAverageCostPerUnit] = useState("");
    const [addItemizedInventory, itemizedInventoryAddState] = useAddItemizedInventoryMutation()
    const [expiryDate, setExpiryDate] = useState("");
    const { data, isError, isLoading } = useGetInventoryItemQuery({ inventoryId });
    const toast = useToast()
    const handleSubmit = () => {
        if (!totalQuantity || !averageCostPerUnit || !expiryDate) {
            return toast({
                title: 'Please add missing fields.',
                description: `Field(s) Missing: ${!totalQuantity && "Total Quatity, "}${!averageCostPerUnit && "Average Cost Per Unit, "}${!expiryDate && "Expiry Date, "} `,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
        addItemizedInventory({ totalQuantity, notes, averageCostPerUnit, expiryDate, inventoryId })
    };

    if (isError) {
        return <AlterBox status="error" message="Unable to fetch Inventory Item. Please try Again!" />
    }
    if (isLoading) {
        return <Loading />
    }
    const isProcessing = itemizedInventoryAddState.isSuccess || itemizedInventoryAddState.isLoading || itemizedInventoryAddState.isError
    return (
        <Box style={{ ...FORM_STYLE }}>
            <Stack spacing={4}>
                <FormControl>
                    <FormLabel>
                        Name of Item <Text as="span">*</Text>
                    </FormLabel>
                    <Input isDisabled value={data?.name} type="text" />
                </FormControl>
                <FormControl>
                    <FormLabel>
                        Unit <Text as="span">*</Text>
                    </FormLabel>
                    <Input isDisabled value={data?.unit} type="text" />
                </FormControl>
                <FormControl>
                    <FormLabel>
                        Inventory Notes
                    </FormLabel>
                    <Textarea isDisabled value={data?.notes} />
                </FormControl>
                <FormControl>
                    <FormLabel>
                        Quantity
                    </FormLabel>
                    <Input onChange={(e) => setTotalQuantity(e.target.value)} isRequired type="number" />
                </FormControl>
                <FormControl>
                    <FormLabel>
                        Average Cost per Unit
                    </FormLabel>
                    <Input onChange={(e) => setAverageCostPerUnit(e.target.value)} isRequired type="number" />
                </FormControl>
                <FormControl>
                    <FormLabel>
                        Notes
                    </FormLabel>
                    <Textarea onChange={(e) => setNotes(e.target.value)} isRequired />
                </FormControl>
                <FormControl>
                    <FormLabel>
                        Expiry Date
                    </FormLabel>
                    <Input onChange={(e) => setExpiryDate(e.target.value)} isRequired type="datetime-local" />
                </FormControl>
                <Button disabled={isLoading} size="lg" onClick={handleSubmit}>
                    Add Itemized Item
                </Button>
                {isProcessing && <AlterBox status={itemizedInventoryAddState.isSuccess ? "success" : itemizedInventoryAddState.isError ? "error" : "loading"} />}
            </Stack>
        </Box>
    );
};
