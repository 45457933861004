import { Box, IconButton, Spinner, Table, TableCaption, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr } from "@chakra-ui/react"
import { AddIcon, DeleteIcon } from "@chakra-ui/icons"
import { FiDelete, FiEye } from "react-icons/fi"
import { isEven } from "../../../../common/utils/isEven"
import { AlterBox } from "../../../../common/components/AlertBox"
import { useGetLowInventoryListQuery } from "../../../../Inventory/inventoryApi"
import { Dispatch, SetStateAction, useEffect } from "react"

const LOW_INVENTORY_THRESHOLD = 100

export const LowInventoryTable = ({ setNumberOfItemsLow }: { setNumberOfItemsLow: Dispatch<SetStateAction<number>> }) => {
    const { data, isLoading, isError } = useGetLowInventoryListQuery({ page: 1, limit: 100, threshold: LOW_INVENTORY_THRESHOLD })
    useEffect(() => {
        if (data && !isLoading && data?.length) {
            setNumberOfItemsLow(data.length)
        }
    }, [data, isLoading])
    return <>
        <TableContainer w="100%">
            <Table size="sm" variant='striped' colorScheme='teal'>
                <TableCaption></TableCaption>
                <Thead>
                    <Tr>
                        <Th>Sr. No.</Th>
                        <Th>Name</Th>
                        <Th isNumeric>Total Quantity</Th>
                        <Th>Unit</Th>
                        <Th>Actions</Th>
                    </Tr>
                </Thead>
                {data?.length ? <Tbody>
                    {data?.map((inventory, index) => {
                        return <Tr bg={isEven(index) ? "cyan.200" : "none"}>
                            <Td>{index + 1}</Td>
                            <Td>{inventory?.name}</Td>
                            <Td isNumeric>{inventory?.totalQuantity}</Td>
                            <Td>{inventory?.unit}</Td>
                            <Td><IconButton onClick={() => window.location.href = `/add-itemized-inventory/${inventory.inventoryId}`} bg="cyan.400" aria-label='Add Item' icon={<AddIcon />} /> <IconButton onClick={() => window.location.href = `/view-itemized-inventory/${inventory.inventoryId}`} marginLeft="2px" bg="teal.400" aria-label='Add Item' icon={<FiEye />} /> </Td>
                        </Tr>
                    })}
                </Tbody> : null}
                {!data?.length && !isLoading && <Text>No Items to show</Text>}
                {isLoading && <Box w="100vw" overflow="hidden" display="flex" alignItems="center" justifyContent="center">
                    <Spinner />
                </Box>}
                <Tfoot>
                    <Tr>
                        <Th>Sr. No.</Th>
                        <Th>Name</Th>
                        <Th isNumeric>Total Quantity</Th>
                        <Th>Unit</Th>
                        <Th>Actions</Th>
                    </Tr>
                </Tfoot>
            </Table>
            {isError && <AlterBox status={"error"} />}
        </TableContainer></>
}