import { PERMISSIONS } from "../../auth/constants"
import { PermissionsGateway } from "../../auth/pages/PermissionsGateway"
import { DashboardNavigationLayout } from "../../common/Layouts/DashboardNavigationLayout/DashboardNavigationLayout"
import { ViewInventoryView } from "../components/ViewInventory/ViewInventoryView"
import { ViewInventoryAuthGateway } from "./ViewInventoryAuthGateway"

export const ViewInventory = () => {
    return <ViewInventoryAuthGateway>

        <PermissionsGateway requiredPermissions={[PERMISSIONS.INVENTORY]}>
            <DashboardNavigationLayout>
                <ViewInventoryView />
            </DashboardNavigationLayout>
        </PermissionsGateway>
    </ViewInventoryAuthGateway>
}