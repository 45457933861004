import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    Textarea,
    Stack,
    Text,
    Alert,
    AlertIcon,
    useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import { FORM_STYLE } from '../../common/constants/styles';
import { useAddInventoryMutation } from '../inventoryApi';
import { AlterBox } from '../../common/components/AlertBox';

export const AddInventoryItemForm = () => {
    const [name, setName] = useState("");
    const [notes, setNotes] = useState("");
    const [unit, setUnit] = useState("");
    const toast = useToast()
    const handleSubmit = () => {
        if (!name || !unit) {
            return toast({
                title: 'Please add missing fields.',
                description: `Field(s) Missing: ${!name && "Name, "}${!unit && "Unit, "} `,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
        addInventory({ name, notes, unit })
    };
    const [addInventory, { isSuccess, isLoading, isError }] = useAddInventoryMutation()
    const isProcessing = isSuccess || isLoading || isError
    return (
        <Box style={{ ...FORM_STYLE }}>
            <Stack spacing={4}>
                <FormControl>
                    <FormLabel>
                        Name of Item <Text as="span">*</Text>
                    </FormLabel>
                    <Input onChange={e => setName(e.target.value)} isRequired type="text" />
                </FormControl>
                <FormControl>
                    <FormLabel>
                        Unit <Text as="span">*</Text>
                    </FormLabel>
                    <Input onChange={e => setUnit(e.target.value)} isRequired type="text" />
                </FormControl>
                <FormControl>
                    <FormLabel>
                        Notes
                    </FormLabel>
                    <Textarea onChange={e => setNotes(e.target.value)} />
                </FormControl>
                <Button disabled={isProcessing} size="lg" onClick={handleSubmit}>
                    Add Inventory Item
                </Button>
                {isProcessing && <AlterBox status={isSuccess ? "success" : isError ? "error" : "loading"} />}
            </Stack>
        </Box>
    );
};
