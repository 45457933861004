import { Box, IconButton, Spinner, Table, TableCaption, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr } from "@chakra-ui/react"
import { GetInventoryResponse, useGetInventoryListQuery, useRemoveInventoryItemMutation } from "../../inventoryApi"
import { AlterBox } from "../../../common/components/AlertBox"
import { isEven } from "../../../common/utils/isEven"
import { AddIcon, DeleteIcon } from "@chakra-ui/icons"
import { FiEye, FiShoppingCart } from "react-icons/fi"
import { FaQuora } from "react-icons/fa";
import { useState } from "react"
import { QuickAddModal } from "../AddItemizedInventory/QuickAddModal"
import { QuickCheckoutModal } from "../ViewItemizedInventory/QuickCheckoutModal"

export const InventoryTable = () => {
    const { data, isLoading, isError } = useGetInventoryListQuery({ page: 1, limit: 100 })
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [isQuickAddModalOpen, setIsQuickAddModalOpen] = useState(false)
    const [isQuickCheckoutModalOpen, setIsQuickCheckoutModalOpen] = useState(false)
    const [removeInventoryItem, removeInventoryItemState] = useRemoveInventoryItemMutation()
    const selectedInventory = data ? data[selectedIndex] : {} as GetInventoryResponse;
    const handleQuickAddModalOpen = (index: number) => {
        setSelectedIndex(index)
        setIsQuickAddModalOpen(true)
    }
    const handleQuickCheckoutModalOpen = (index: number) => {
        setSelectedIndex(index)
        setIsQuickCheckoutModalOpen(true)
    }
    return <>
        <TableContainer w="100%">
            <Table size='sm' variant='striped' colorScheme='teal'>
                <TableCaption></TableCaption>
                <Thead>
                    <Tr>
                        <Th>Sr. No.</Th>
                        <Th>Name</Th>
                        <Th isNumeric width="50px">Total Quantity</Th>
                        <Th>Unit</Th>
                        <Th isNumeric>Average Cost per unit</Th>
                        <Th isNumeric>Total Used Till date</Th>
                        <Th>Notes</Th>
                        <Th>Actions</Th>
                        <Th>Quick Actions</Th>
                        <Th>Delete</Th>
                    </Tr>
                </Thead>
                {data?.length ? <Tbody>
                    {data?.map((inventory, index) => {
                        return <Tr bg={isEven(index) ? "cyan.200" : "none"}>
                            <Td paddingRight="0px">{index + 1}</Td>
                            <Td paddingLeft="0px" paddingRight="0px">{inventory?.name}</Td>
                            <Td paddingLeft="0px" paddingRight="0px" isNumeric>{inventory?.totalQuantity}</Td>
                            <Td paddingRight="0px">{inventory?.unit}</Td>
                            <Td isNumeric>{inventory?.averageCostPerUnit}</Td>
                            <Td isNumeric>{inventory?.totalQuantityUsedTillDate}</Td>
                            <Td>{inventory?.notes}</Td>
                            <Td><IconButton onClick={() => window.location.href = `/add-itemized-inventory/${inventory.inventoryId}`} bg="cyan.400" aria-label='Add Item' icon={<AddIcon />} /> <IconButton onClick={() => window.location.href = `/view-itemized-inventory/${inventory.inventoryId}`} marginLeft="2px" bg="teal.400" aria-label='Add Item' icon={<FiEye />} /> </Td>
                            <Td>
                                <IconButton onClick={() => handleQuickAddModalOpen(index)} bg="yellow.400" aria-label='Quick Add Item' icon={<><AddIcon /><sup><FaQuora /></sup></>} />
                                <IconButton onClick={() => handleQuickCheckoutModalOpen(index)} bg="blue.400" marginLeft="10px" aria-label='Quick Checkout Item' icon={<><FiShoppingCart /><sup><FaQuora /></sup></>} />
                            </Td>
                            <Td><IconButton onClick={() => removeInventoryItem({ inventoryId: inventory.inventoryId })} marginLeft="2px" bg="red.400" aria-label='Delete Item' icon={<DeleteIcon />} /></Td>
                        </Tr>
                    })}
                </Tbody> : null}
                {!data?.length && !isLoading && <Text>No Items to show</Text>}
                {isLoading && <Box w="100vw" overflow="hidden" display="flex" alignItems="center" justifyContent="center">
                    <Spinner />
                </Box>}
                <Tfoot>
                    <Tr>
                        <Th>Sr. No.</Th>
                        <Th>Name</Th>
                        <Th isNumeric>Total Quantity</Th>
                        <Th>Unit</Th>
                        <Th isNumeric>Average Cost per unit</Th>
                        <Th isNumeric>Total Used Till date</Th>
                        <Th>Notes</Th>
                        <Th>Actions</Th>
                        <Th>Delete</Th>
                    </Tr>
                </Tfoot>
            </Table>
            {isError && <AlterBox status={"error"} />}
            {removeInventoryItemState.isError && <AlterBox status={"error"} />}
            {removeInventoryItemState.isSuccess && <AlterBox status={"success"} message="Deleted Successfully" />}
            {isQuickAddModalOpen && <QuickAddModal inventoryId={selectedInventory.inventoryId} averageCostPerUnit={`${selectedInventory.averageCostPerUnit || 0}`} name={selectedInventory.name || ""} isOpen={isQuickAddModalOpen} onClose={() => setIsQuickAddModalOpen(false)} />}
            {isQuickCheckoutModalOpen && <QuickCheckoutModal inventoryId={selectedInventory.inventoryId} name={selectedInventory.name || ""} isOpen={isQuickCheckoutModalOpen} onClose={() => setIsQuickCheckoutModalOpen(false)} />}
        </TableContainer></>
}