import { Loading } from '../../common/Loading';
import { useCurrentUser } from '../hooks/useCurrentUser';

export const PermissionsGateway = ({ requiredPermissions, redirectTo = "/", children }: { children: JSX.Element, requiredPermissions: Array<string>, redirectTo?: string }) => {
    const { permissions, role, isLoading } = useCurrentUser();
    if (isLoading) {
        return <Loading />;
    }
    if (!requiredPermissions.some(permission => permissions.includes(permission)) && !requiredPermissions.includes("all") && role !== "admin") {
        window.location.href = redirectTo
        return <></>
    }

    return <>{children}</>;
};
