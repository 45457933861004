import { AdminDashboardView } from '../components/AdminDashboardView';
import { AdminNavigationLayout } from '../components/AdminNavigationLayout';
import { AdminAuthGateway } from './AdminAuthGateway';

export const AdminDashboard = () => {
  return (
    <>
      <AdminAuthGateway>
        <AdminNavigationLayout>
          <AdminDashboardView />
        </AdminNavigationLayout>
      </AdminAuthGateway>
    </>
  );
};
