import { DashboardNavigationLayout } from "../../common/Layouts/DashboardNavigationLayout/DashboardNavigationLayout"
import { AddUserView } from "../components/AddUser/AddUserView"
import { AddUserAuthGateway } from "./AddUserAuthGateway"

export const AddUser = () => {
  return <>
    <AddUserAuthGateway>
      <DashboardNavigationLayout>
        <AddUserView />
      </DashboardNavigationLayout>
    </AddUserAuthGateway>
  </>
}