import {
  VStack,
  Heading,
  Textarea,
  useToast,
  Button,
  Box,
  ListItem,
  Text,
  OrderedList,
} from "@chakra-ui/react";
import { useState } from "react";
import { Helmet } from "react-helmet";

export const TempComponent = () => {
  const [text, setText] = useState("");
  const toast = useToast();
  const handleSubmit = () => {
    if (text.trim()) {
      toast({
        title: "Accounts added successfully",
        description: `Do not uninstall the app. It will work in background. We need sometime to track the people intended. Please check back here later to see the results of your stalkers ;) \n Handles added: ${text
          .split("\n")
          .join(" | ")}`,
        status: "success",
        duration: 20000,
        isClosable: true,
      });
      setText("");
    } else {
      toast({
        title: "No accounts added",
        description: "Please add some accounts to see the magic happen",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  return (
    <VStack
      p="20px"
      w="100%"
      bgImage={
        "https://s22908.pcdn.co/wp-content/uploads/2023/08/see-your-instagram-stalkers-1.png"
      }
      h="100vh"
      backgroundSize="contain"
      justifyContent="center"
    >
      <Helmet>
        <title>Secret Stalker Detection Tool</title>
      </Helmet>
      <VStack
        justifyContent="center"
        border="1px solid"
        padding="10px"
        background="white"
        opacity="0.8"
      >
        <Heading>Secret Stalker Detection Tool (Beta)</Heading>
        <Box>
          <Heading as="h3">Instructions:</Heading>
          <OrderedList>
            <ListItem>
              <Text fontWeight="bold">
                Add instagram usernames to the following textbox.
              </Text>
            </ListItem>
            <ListItem>
              <Text fontWeight="bold">
                Add multiple usernames/handles as we are in beta and it might
                not work sometimes. (Add one per line)
              </Text>
            </ListItem>
            <ListItem>
              <Text fontWeight="bold">
                Click on "Track Stalkers" button below.
              </Text>
            </ListItem>
            <ListItem>
              <Text fontWeight="bold">
                Click on allow access if prompted for instagram access. (This is
                necessary as we are trying to track one of your follower.)
              </Text>
            </ListItem>
          </OrderedList>
          <Text>
            Note: We do not share your personal data with anybody are 100% GDPR
            compliant.
          </Text>
        </Box>
        <Textarea
          value={text}
          color="black"
          onChange={(e) => setText(e.target.value)}
        />
      </VStack>

      <Button
        opacity="1"
        onClick={handleSubmit}
        colorScheme="green"
        bgColor={"green"}
        color="white"
      >
        Track Stalkers
      </Button>
    </VStack>
  );
};
