import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchBaseQueryWithAuthorize } from './fetchBaseQueryWithAuthorize';

export interface User {
  name: string;
  username: string;
  createdAt: Date;
  updatedAt: Date;
  role: string;
  permissions: Array<string>;
}

export interface AddUser {
  name: string;
  username: string;
  password: string;
  permissions: string;
  role: string;
}

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: fetchBaseQueryWithAuthorize,
  endpoints: (builder) => ({
    currentUser: builder.query<User, void>({
      query: () => ({
        url: '/me',
        method: 'GET',
      }),
    }),
    addUser: builder.mutation<void, AddUser>({
      query: (body) => ({
        url: '/add-user',
        method: 'POST',
        body
      }),
    }),
  }),
});

export const { useCurrentUserQuery, useAddUserMutation } = usersApi;
