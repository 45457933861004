import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  Spinner,
} from '@chakra-ui/react';
import { Logo } from '../../../common/Logo';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLogin } from '../../../auth/hooks/useLogin';
import { useEffect, useState } from 'react';

export default function LoginCard() {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get('redirectTo');
  const { userLogin, isLoggedIn, isUnknownError, isInvalidUsernamePassword, isLoading } = useLogin();
  const login = () => {
    userLogin(username, password);
  };

  useEffect(() => {
    if (isLoggedIn && !isLoading && !isInvalidUsernamePassword && !isUnknownError) {
      window.location.href = redirectTo || '/';
    }
  }, [isInvalidUsernamePassword, isLoading, isLoggedIn, isUnknownError, redirectTo]);

  return (
    <Flex minH={'100vh'} align={'center'} justify={'center'}>
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <Stack align={'center'}>
          <Logo height={100} />
          <Heading fontSize={'4xl'}>Sign in to your account</Heading>
          {/* <Text fontSize={'lg'} color={'gray.600'}>
            to enjoy all of our cool{' '}
            <Text cursor="pointer" onClick={() => navigate('/')} color={'blue.400'}>
              features
            </Text>{' '}
            ✌️
          </Text> */}
        </Stack>
        <Box rounded={'lg'} bg={useColorModeValue('white', 'gray.700')} boxShadow={'lg'} p={8}>
          <Stack spacing={4}>
            <FormControl>
              <FormLabel>Phone Number</FormLabel>
              <Input onChange={(e) => setUsername(e.target.value)} />
            </FormControl>
            <FormControl>
              <FormLabel>Password</FormLabel>
              <Input onChange={(e) => setPassword(e.target.value)} type="password" />
            </FormControl>
            {!isLoading && (isInvalidUsernamePassword || isUnknownError) && (
              <Text color="red">
                {isInvalidUsernamePassword
                  ? 'Invalid Username or password'
                  : 'An eror occured. Please try again later.'}
              </Text>
            )}
            <Stack spacing={10}>
              <Stack direction={{ base: 'column', sm: 'row' }} align={'start'} justify={'space-between'}>
                <Checkbox>Remember me</Checkbox>
                <Text color={'blue.400'}>Forgot password?</Text>
              </Stack>
              <Button
                bg={'blue.400'}
                color={'white'}
                _hover={{
                  bg: 'blue.500',
                }}
                onClick={login}
                isDisabled={isLoading}
              >
                {isLoading ? <Spinner /> : "Sign in"}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}
