import { Box, Heading, Text } from "@chakra-ui/react"
import { LowInventoryTable } from "./LowInventoryTable"
import { useState } from "react"

export const LowInventoryWidget = () => {
    const [numberOfItemsLow, setNumberOfItemsLow] = useState(0)
    return <Box w="100%" h="100%" maxH="300px" overflow="auto">
        <Box bg="white" p="10px"><Heading size="md">Items Low on inventory: <Text as="span" p="7px" border="1px solid" borderRadius="50%">{numberOfItemsLow}</Text></Heading></Box>
        <LowInventoryTable setNumberOfItemsLow={setNumberOfItemsLow} />
    </Box>
}