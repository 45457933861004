import { Image } from '@chakra-ui/react';

export const Logo = ({ height }: { height?: number }) => {
  return (
    <Image
      src="/momos-garage-logo.png"
      alt="Momos Garage Logo"
      loading="lazy"
      h={height ? `${height}px` : '150px'}
      marginBottom="20px"
    />
  );
};
