import { Box, Button, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, ModalProps, Text } from "@chakra-ui/react"
import { useAddItemizedInventoryMutation, useCheckoutItemizedInventoryItemMutation, useGetItemizedInventoryListQuery } from "../../inventoryApi";
import { useEffect, useState } from "react";
import { AlterBox } from "../../../common/components/AlertBox";
import { Loading } from "../../../common/Loading";

export interface QuickAddModalProps extends Omit<ModalProps, "children"> { name: string; inventoryId: string; }

export const QuickCheckoutModal = ({ name, inventoryId, isOpen, onClose }: QuickAddModalProps) => {
    const [quantity, setQuantity] = useState(0)
    const [noUnitsAvailableError, setNoUnitsAvailableError] = useState(false)
    const [isCheckoutSuccessful, setIsCheckoutSuccessful] = useState(false)

    const { data: itemizedInventory, isLoading: isItemizedInventoryLoading, isError: isItemizedInventoryError } = useGetItemizedInventoryListQuery({ page: 1, limit: 100, inventoryId })
    const [checkoutItemizedInventory, checkoutItemizedInventoryStatus] = useCheckoutItemizedInventoryItemMutation()

    useEffect(() => {
        if (isCheckoutSuccessful) {
            onClose()
        }
    }, [isCheckoutSuccessful])

    const totalUnitsAvailable = itemizedInventory?.reduce((accumulator, currentValue) => accumulator + parseInt(currentValue.totalQuantity), 0) || 0

    const handleQuickCheckout = async () => {
        if (totalUnitsAvailable < quantity) {
            setNoUnitsAvailableError(true)
            return
        }

        if (quantity && quantity > 0 && itemizedInventory?.length && itemizedInventory?.length > 0) {
            let totalItemsRemaining = quantity;
            for (let i = itemizedInventory.length - 1; totalItemsRemaining > 0; i--) {
                const itemizedInventoryId = itemizedInventory[i]._id
                const quantityToDeduct = totalItemsRemaining <= parseInt(itemizedInventory[i].totalQuantity) ? totalItemsRemaining : parseInt(itemizedInventory[i].totalQuantity)
                await checkoutItemizedInventory({ inventoryId, itemizedInventoryId, quantity: `${quantityToDeduct}` })
                totalItemsRemaining = totalItemsRemaining - quantityToDeduct;
            }
            if (!checkoutItemizedInventoryStatus.isError) {
                setIsCheckoutSuccessful(true)
            }
        }
    }

    if (isItemizedInventoryLoading) {
        return <Loading />
    }


    return (<Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>Checkout {name} </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <Box borderRadius="2px" border="1px solid" marginBottom="20px" padding="5px">
                    <Text>Checking out: {name}</Text>
                    <Text>Total units available: {totalUnitsAvailable}</Text>
                </Box>
                {isItemizedInventoryError && <Text color="red.400">Error Fetching Inventory. Please try again.</Text>}
                {checkoutItemizedInventoryStatus.isError && <Text color="red.400">Error checking out.</Text>}
                {noUnitsAvailableError && <Text color="red.400">You are trying to checkout more units than available quantity. Please verify again.</Text>}
                <Input placeholder="Enter Quanity" isDisabled={isItemizedInventoryLoading} onChange={(e) => setQuantity(parseInt(e.target.value))} type="number" isRequired />
                {checkoutItemizedInventoryStatus.isError && <AlterBox status={"error"} message="Error checking out inventory. Please try again" />}
            </ModalBody>

            <ModalFooter>
                <Button colorScheme='red' mr={3} onClick={onClose}>
                    Close
                </Button>
                <Button onClick={handleQuickCheckout} colorScheme='blue' isDisabled={!(quantity && quantity > 0) || isItemizedInventoryLoading || isItemizedInventoryError}>Quick Checkout</Button>
            </ModalFooter>
        </ModalContent>
    </Modal>)
}