import { useLoginRedirect } from "../../auth/hooks/useLoginRedirect";
import { Loading } from "../../common/Loading";

export const ViewInventoryAuthGateway = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const { isLoading, isLoggedIn } = useLoginRedirect("/login");
  if (isLoading || !isLoggedIn) {
    return <Loading />;
  }
  return isLoggedIn && <>{children}</>;
};
