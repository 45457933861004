import { Flex } from '@chakra-ui/react';
import { AddInstituteForm } from './AddInstituteForm';

export const AdminDashboardView = () => {
  return (
    <Flex minH={'100vh'} align={'center'} justify={'center'}>
      <AddInstituteForm />
    </Flex>
  );
};
