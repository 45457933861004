import { Box, Flex } from "@chakra-ui/react";
import { LowInventoryWidget } from "./Widgets/LowInventoryWidget/LowInventoryWidget";
import { ToolsListWidget } from "./Widgets/ToolsListWidget/ToolsListWidget";

export const DashboardView = () => {
  return (
    <Box minH={"100vh"}>
      <LowInventoryWidget />
      <Box marginTop="20px">
        <ToolsListWidget />
      </Box>
    </Box>
  );
};
