import { useState, useEffect } from "react";
import { useCurrentUserQuery } from "../usersApi";
import { useLogout } from "./useLogout";
import { USER_ROLES } from "../constants";
export const useLoginRedirect = (
  redirectTo = "/",
  requiredRoles: Array<string> = []
) => {
  const { data, isLoading } = useCurrentUserQuery();
  const logout = useLogout();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    if (!isLoading) {
      if (
        data?.username &&
        (!requiredRoles ||
          requiredRoles.length < 1 || //if norequiredRoles are given, it means open for all roles and only loggin check needs to be done
          requiredRoles.includes(data.role) ||
          data.role === USER_ROLES.admin)
      ) {
        setIsLoggedIn(true);
      } else {
        logout(redirectTo);
      }
    }
  }, [data, isLoading, logout, redirectTo, requiredRoles]);
  return { isLoggedIn, isLoading };
};
