import { Box, Card, CardBody, CardHeader, HStack, Heading, Stack, StackDivider, Text } from "@chakra-ui/react";
import { useGetInventoryItemQuery } from "../../inventoryApi";
import { Loading } from "../../../common/Loading";

export const ItemizedInventoryParentInfo = ({ inventoryId }: { inventoryId: string }) => {

    const { data, isError, isLoading } = useGetInventoryItemQuery({ inventoryId });

    if (isError) {
        return null;
    }

    if (isLoading) {
        <Loading />
    }

    return <Card w="100%" mb='10px'>
        <CardHeader>
            <Heading size='md'>Inventory Info</Heading>
        </CardHeader>

        <CardBody>
            <Stack direction={["column", "row"]} >
                <Stack w="50%" divider={<StackDivider />} spacing='2'>
                    <Box>
                        <Heading size='xs' textTransform='uppercase'>
                            Name
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                            {data?.name}
                        </Text>
                    </Box>
                    <Box>
                        <Heading size='xs' textTransform='uppercase'>
                            Unit
                        </Heading>
                        <Text pt='2' fontSize='sm'>

                            {data?.unit}
                        </Text>
                    </Box>
                    <Box>
                        <Heading size='xs' textTransform='uppercase'>
                            Notes
                        </Heading>
                        <Text pt='2' fontSize='sm'>

                            {data?.notes}
                        </Text>
                    </Box>
                    <Box>
                        <Heading size='xs' textTransform='uppercase'>
                            Average Cost per Unit
                        </Heading>
                        <Text pt='2' fontSize='sm'>

                            {data?.averageCostPerUnit}
                        </Text>
                    </Box>
                </Stack>
                <Stack w="50%" divider={<StackDivider />} spacing='2'>
                    <Box>
                        <Heading size='xs' textTransform='uppercase'>
                            Total Remaining
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                            {data?.totalQuantity}
                        </Text>
                    </Box>
                    <Box>
                        <Heading size='xs' textTransform='uppercase'>
                            Total Used Till Date
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                            {data?.totalQuantityUsedTillDate}
                        </Text>
                    </Box>
                </Stack>
            </Stack>
        </CardBody>
    </Card>
}