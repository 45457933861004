import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchBaseQueryWithAuthorize } from '../auth/fetchBaseQueryWithAuthorize';

export interface Inventory {
    name: string;
    unit: string;
    notes?: string;
}

export interface ItemizedInventory {
    totalQuantity: string;
    averageCostPerUnit: string;
    expiryDate: string;
    inventoryId: string;
    notes?: string;
    assignedToEmployeeId?: string;
    warehouseId?: string;
}

export interface GetInventoryResponse extends Inventory {
    totalQuantity?: number;
    updatedAt?: string;
    createdAt?: string;
    inventoryId: string;
    totalQuantityUsedTillDate?: number;
    averageCostPerUnit?: number;
}

export interface GetItemizedInventoryResponse extends ItemizedInventory {
    totalQuantityUsedTillDate?: number;
    _id: string;
}

export const inventoryApi = createApi({
    reducerPath: 'inventoryApi',
    baseQuery: fetchBaseQueryWithAuthorize,
    tagTypes: ['InventoryItems', 'ItemizedInventoryItems'],
    endpoints: (builder) => ({
        addInventory: builder.mutation<void, Inventory>({
            query: (body) => ({
                url: '/inventory-item',
                method: 'POST',
                body
            }),
            invalidatesTags: ['InventoryItems', 'ItemizedInventoryItems']
        }),
        addItemizedInventory: builder.mutation<void, ItemizedInventory>({
            query: (body) => ({
                url: '/itemized-inventory-item',
                method: 'POST',
                body
            }),
        }),
        getInventoryList: builder.query<Array<GetInventoryResponse>, { limit: number, page: number }>({
            query: ({ limit, page }) => ({
                url: `/inventory-list?page=${page}&limit=${limit}`,
                method: 'GET',
            }),
            providesTags: ['InventoryItems']
        }),
        getLowInventoryList: builder.query<Array<GetInventoryResponse>, { limit: number, page: number, threshold: number }>({
            query: ({ limit, page, threshold }) => ({
                url: `/low-inventory-list?page=${page}&limit=${limit}&threshold=${threshold}`,
                method: 'GET',
            }),
            providesTags: ['InventoryItems']
        }),
        getItemizedInventoryList: builder.query<Array<GetItemizedInventoryResponse>, { limit: number, page: number, inventoryId: string }>({
            query: ({ limit, page, inventoryId }) => ({
                url: `/itemized-inventory-list?page=${page}&limit=${limit}&inventoryId=${inventoryId}`,
                method: 'GET',
            }),
            providesTags: ['ItemizedInventoryItems']
        }),
        getInventoryItem: builder.query<GetInventoryResponse, { inventoryId: string }>({
            query: ({ inventoryId }) => ({
                url: `/inventory-item?inventoryId=${inventoryId}`,
                method: 'GET',
            }),
        }),
        removeInventoryItem: builder.mutation<void, { inventoryId: string }>({
            query: ({ inventoryId }) => ({
                url: `/inventory-item?inventoryId=${inventoryId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['InventoryItems', 'ItemizedInventoryItems']
        }),
        checkoutItemizedInventoryItem: builder.mutation<void, { inventoryId: string, itemizedInventoryId: string, quantity: string }>({
            query: (body) => ({
                url: `/checkout-itemized-inventory-item`,
                method: 'PATCH',
                body
            }),
            invalidatesTags: ['InventoryItems', 'ItemizedInventoryItems']
        }),
    }),
});

export const { useAddInventoryMutation, useGetInventoryListQuery, useGetInventoryItemQuery, useAddItemizedInventoryMutation, useGetItemizedInventoryListQuery, useRemoveInventoryItemMutation, useCheckoutItemizedInventoryItemMutation, useGetLowInventoryListQuery } = inventoryApi;
