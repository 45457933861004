import { Box, Text } from "@chakra-ui/react"
import { AddInventoryItemForm } from "../AddInventoryItemForm"
import { AddItemizedInventoryItemForm } from "./AddItemizedInventoryForm"
import { useParams } from "react-router-dom"

export const AddItemizedInventoryView = () => {
    const { inventoryId } = useParams()
    if (!inventoryId) {
        return <Text>Invalid URL</Text>
    }
    return <Box h="100%" display="flex" justifyContent="center" alignItems="center">
        <AddItemizedInventoryItemForm inventoryId={inventoryId} />
    </Box>
}