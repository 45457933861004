import { Box, Button, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, ModalProps, Text } from "@chakra-ui/react"
import { useAddItemizedInventoryMutation } from "../../inventoryApi";
import { useEffect, useState } from "react";
import { AlterBox } from "../../../common/components/AlertBox";

export interface QuickAddModalProps extends Omit<ModalProps, "children"> { name: string; inventoryId: string; averageCostPerUnit: string; }

export const QuickAddModal = ({ name, inventoryId, averageCostPerUnit, isOpen, onClose }: QuickAddModalProps) => {
    const [quantity, setQuantity] = useState("")

    const [addItemizedInventory, itemizedInventoryAddState] = useAddItemizedInventoryMutation()
    const [expiryDate, setExpiryDate] = useState("");

    const handleAdd = () => {
        if (quantity && parseInt(quantity) > 0 && inventoryId) {
            addItemizedInventory({ totalQuantity: quantity, notes: "", averageCostPerUnit, expiryDate, inventoryId })
        }
    }
    useEffect(() => {
        if (itemizedInventoryAddState.isSuccess) {
            onClose()
        }
    }, [itemizedInventoryAddState])

    return (<Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>Add {name} </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <Box borderRadius="2px" border="1px solid" marginBottom="20px" padding="5px"><Text>Adding: {name}</Text></Box>

                <Input placeholder="Enter Quanity" isDisabled={itemizedInventoryAddState.isLoading} onChange={(e) => setQuantity(e.target.value)} type="number" isRequired />
                <FormControl>
                    <FormLabel>
                        Expiry Date
                    </FormLabel>
                    <Input onChange={(e) => setExpiryDate(e.target.value)} isRequired type="datetime-local" />
                </FormControl>
                {itemizedInventoryAddState.isError && <AlterBox status={"error"} message="Error adding inventory. Please try again" />}
            </ModalBody>

            <ModalFooter>
                <Button colorScheme='red' mr={3} onClick={onClose}>
                    Close
                </Button>
                <Button onClick={handleAdd} colorScheme='blue' isDisabled={!(quantity && parseInt(quantity) > 0) || itemizedInventoryAddState.isLoading}>Quick Add</Button>
            </ModalFooter>
        </ModalContent>
    </Modal>)
}