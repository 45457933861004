import { Box, Button, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, ModalProps, Text } from "@chakra-ui/react"
import { useCheckoutItemizedInventoryItemMutation } from "../../inventoryApi";
import { useEffect, useState } from "react";
import { AlterBox } from "../../../common/components/AlertBox";

export interface CheckoutModalProps extends Omit<ModalProps, "children"> { name: string; inventoryId: string; itemizedInventoryId: string; }

export const CheckoutModal = ({ name, inventoryId, itemizedInventoryId, isOpen, onClose }: CheckoutModalProps) => {
    const [quantity, setQuantity] = useState("")

    const [checkoutItemizedInventory, checkoutItemizedInventoryStatus] = useCheckoutItemizedInventoryItemMutation()

    const handleCheckout = () => {
        if (quantity && parseInt(quantity) > 0 && inventoryId && itemizedInventoryId) {
            checkoutItemizedInventory({ inventoryId, itemizedInventoryId, quantity })
        }
    }
    useEffect(() => {
        if (checkoutItemizedInventoryStatus.isSuccess) {
            onClose()
        }
    }, [checkoutItemizedInventoryStatus])

    return (<Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>Checkout {name} </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <Box borderRadius="2px" border="1px solid" marginBottom="20px" padding="5px"><Text>Checking out for ID: {itemizedInventoryId}</Text></Box>
                <Input placeholder="Enter Quanity" isDisabled={checkoutItemizedInventoryStatus.isLoading} onChange={(e) => setQuantity(e.target.value)} type="number" isRequired />
                {checkoutItemizedInventoryStatus.isError && <AlterBox status={"error"} message="Error checking out. Please try again" />}
            </ModalBody>

            <ModalFooter>
                <Button colorScheme='red' mr={3} onClick={onClose}>
                    Close
                </Button>
                <Button onClick={handleCheckout} colorScheme='blue' isDisabled={!(quantity && parseInt(quantity) > 0) || checkoutItemizedInventoryStatus.isLoading}>Checkout</Button>
            </ModalFooter>
        </ModalContent>
    </Modal>)
}