import { Flex, FlexProps, Icon, Link } from '@chakra-ui/react';
import { IconType } from 'react-icons';
import { ReactText } from 'react';

interface NavItemProps extends FlexProps {
  icon: IconType;
  href: string;
  children: ReactText;
}

export const NavItem = ({ icon, href, children, ...rest }: NavItemProps) => {
  const isActive = href === window?.location?.pathname;
  return (
    <Link href={href} style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        bg={isActive ? 'cyan.400' : 'white'}
        color={isActive ? 'white' : 'black'}
        _hover={{
          bg: 'cyan.400',
          color: 'white',
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};
