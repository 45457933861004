import { Box, IconButton, Spinner, Table, TableCaption, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr } from "@chakra-ui/react"
import { useCheckoutItemizedInventoryItemMutation, useGetInventoryListQuery, useGetItemizedInventoryListQuery } from "../../inventoryApi"
import { AlterBox } from "../../../common/components/AlertBox"
import { isEven } from "../../../common/utils/isEven"
import { AddIcon } from "@chakra-ui/icons"
import { FiEye, FiShoppingCart } from "react-icons/fi"
import { CheckoutModal } from "./CheckoutModal"
import { useState } from "react"

export const ItemizedInventoryTable = ({ inventoryId, handleCheckout }: { inventoryId: string, handleCheckout: (id: string) => void }) => {
    const { data, isLoading, isError } = useGetItemizedInventoryListQuery({ page: 1, limit: 100, inventoryId })
    return <>
        <TableContainer w="100%">
            <Table variant='striped' colorScheme='teal'>
                <TableCaption></TableCaption>
                <Thead>
                    <Tr>
                        <Th>Sr. No.</Th>
                        <Th isNumeric>Total Quantity</Th>
                        <Th>Expiry Date</Th>
                        <Th isNumeric>Average Cost per unit</Th>
                        <Th isNumeric>Total Used Till date</Th>
                        <Th>Notes</Th>
                        <Th>Actions</Th>
                    </Tr>
                </Thead>
                {data?.length ? <Tbody>
                    {data?.map((inventory, index) => {
                        return inventory?.totalQuantity ? <Tr bg={isEven(index) ? "cyan.200" : "none"}>
                            <Td>{index + 1}</Td>
                            <Td isNumeric>{inventory?.totalQuantity}</Td>
                            <Td>{`${new Date(inventory?.expiryDate).toDateString()}`}</Td>
                            <Td isNumeric>{inventory?.averageCostPerUnit}</Td>
                            <Td isNumeric>{inventory?.totalQuantityUsedTillDate}</Td>
                            <Td>{inventory?.notes}</Td>
                            <Td>{inventory?.totalQuantity ? <IconButton onClick={() => handleCheckout(inventory._id)} bg="cyan.400" aria-label='Checkout Item' icon={<FiShoppingCart />} /> : null}</Td>
                        </Tr> : null
                    })}
                </Tbody> : null}
                {!data?.length && !isLoading && <Text>No Items to show</Text>}
                {isLoading && <Box w="100vw" overflow="hidden" display="flex" alignItems="center" justifyContent="center">
                    <Spinner />
                </Box>}
                <Tfoot>
                    <Tr>
                        <Th>Sr. No.</Th>
                        <Th isNumeric>Total Quantity</Th>
                        <Th>Expiry Date</Th>
                        <Th isNumeric>Average Cost per unit</Th>
                        <Th isNumeric>Total Used Till date</Th>
                        <Th>Notes</Th>
                        <Th>Actions</Th>
                    </Tr>
                </Tfoot>
            </Table>
            {isError && <AlterBox status={"error"} />}
        </TableContainer></>
}