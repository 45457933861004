import { Button, Box, Text, VStack, useToast, Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FiLogIn, FiLogOut } from "react-icons/fi";
import { QrReader } from "react-qr-reader";
import {
  useAttendanceInMutation,
  useAttendanceOutMutation,
} from "../../attendanceApi";

export const AttendanceKioskView = () => {
  const [isScanning, setIsScanning] = useState(false);
  const [isMarking, setIsMarking] = useState(false);
  const toast = useToast();
  const [mode, setMode] = useState("");
  const [attendanceIn, attendanceInResult] = useAttendanceInMutation();
  const [attendanceOut, attendanceOutResult] = useAttendanceOutMutation();
  const isMarkingAttendance =
    attendanceInResult.isLoading || attendanceOutResult.isLoading;
  const isMarkingAttendanceSuccess =
    attendanceInResult.isSuccess || attendanceOutResult.isSuccess;
  const isMarkingAttendanceError =
    attendanceInResult.isError || attendanceOutResult.isError;
  const name =
    attendanceInResult.data?.name || attendanceOutResult.data?.name || "";

  useEffect(() => {
    if (!isMarkingAttendance && isMarkingAttendanceSuccess) {
      setIsMarking(false);
      toast({
        title: "Attendance Marked",
        description: `Employee: ${name}`,
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [isMarkingAttendance, isMarkingAttendanceSuccess, name, toast]);

  useEffect(() => {
    if (!isMarkingAttendance && isMarkingAttendanceError) {
      setIsMarking(false);
      toast({
        title: "Unable to mark attendance",
        description: `Please try again`,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [
    isMarkingAttendance,
    isMarkingAttendanceError,
    isMarkingAttendanceSuccess,
    name,
    toast,
  ]);

  return (
    <VStack
      w="100vw"
      h="100vh"
      alignContent="center"
      alignItems="center"
      justifyContent="center"
      spacing="50px"
    >
      <Text>Aabrestro Garage Attendance Kiosk</Text>
      {isMarking && <Spinner>Marking Attendance. Please wait...</Spinner>}
      {isScanning && (
        <>
          <Box>
            <Text mb="10px" border="1px solid" padding="5px">
              Please Put your ID card in front of camera
            </Text>
            <Text textAlign="center" border="1px solid" padding="5px">
              {mode === "checkin" ? "Checking In" : "Checking Out"}
            </Text>
          </Box>

          <QrReader
            onResult={(result, error) => {
              if (!!result) {
                const text = result.getText();
                if (mode === "checkin") {
                  attendanceIn({ encodedEmployeeId: text });
                } else {
                  attendanceOut({ encodedEmployeeId: text });
                }
                setIsMarking(true);
                setIsScanning(false);
              }

              if (!!error) {
                console.info(error);
              }
            }}
            constraints={{ facingMode: "user" }}
            ViewFinder={() => <Box w="100vw"></Box>}
          />
        </>
      )}
      {!isScanning && (
        <Button
          leftIcon={<FiLogIn />}
          w="200px"
          h="100px"
          colorScheme="teal"
          variant="solid"
          fontSize="20px"
          onClick={() => {
            setMode("checkin");
            setIsScanning(true);
          }}
        >
          Check In
        </Button>
      )}
      {!isScanning && (
        <Button
          leftIcon={<FiLogOut />}
          w="200px"
          h="100px"
          colorScheme="red"
          variant="solid"
          fontSize="20px"
          onClick={() => {
            setMode("checkout");
            setIsScanning(true);
          }}
        >
          Check Out
        </Button>
      )}
    </VStack>
  );
};
