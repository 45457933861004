import { createBrowserRouter } from "react-router-dom";
import { Login } from "../features/login/pages/Login";
import { AdminDashboard } from "../features/admin/pages/AdminDashboard";
import { NotFoundPage } from "../features/common/NotFoundPage";
import { Dashboard } from "../features/Dashboard/pages/Dashboard";
import { AddInventory } from "../features/Inventory/pages/AddInventory";
import { ViewInventory } from "../features/Inventory/pages/ViewInventory";
import { AddItemizedInventory } from "../features/Inventory/pages/AddItemizedInventory";
import { ViewItemizedInventory } from "../features/Inventory/pages/ViewItemizedInventory";
import { AddUser } from "../features/Users/pages/AddUser";
import { AttendanceKiosk } from "../features/AttendanceKiosk/pages/AttendanceKiosk";
import { TempComponent } from "./temp";

export const router = createBrowserRouter([
  {
    path: "*",
    element: <NotFoundPage />,
  },
  {
    path: "/",
    element: <Dashboard />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/admin-dashboard",
    element: <AdminDashboard />,
  },
  {
    path: "/add-inventory",
    element: <AddInventory />,
  },
  {
    path: "/add-itemized-inventory/:inventoryId",
    element: <AddItemizedInventory />,
  },
  {
    path: "/view-inventory",
    element: <ViewInventory />,
  },
  {
    path: "/view-itemized-inventory/:inventoryId",
    element: <ViewItemizedInventory />,
  },
  {
    path: "/add-user",
    element: <AddUser />,
  },
  {
    path: "/attendance-kiosk",
    element: <AttendanceKiosk />,
  },
  {
    path: "/secret-stalking-detector",
    element: <TempComponent />,
  },
]);
