import { USER_ROLES } from '../../auth/constants';
import { useLoginRedirect } from '../../auth/hooks/useLoginRedirect';
import { Loading } from '../../common/Loading';

export const DashboardAuthGateway = ({ children }: { children: JSX.Element }) => {
    const { isLoading, isLoggedIn } = useLoginRedirect('/login', [USER_ROLES.admin, USER_ROLES.user]);
    if (isLoading || !isLoggedIn) {
        return <Loading />;
    }
    return isLoggedIn && <>{children}</>;
};
