import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchBaseQueryWithAuthorize } from "../auth/fetchBaseQueryWithAuthorize";
export const inventoryApi = createApi({
  reducerPath: "inventoryApi",
  baseQuery: fetchBaseQueryWithAuthorize,
  tagTypes: ["InventoryItems", "ItemizedInventoryItems"],
  endpoints: (builder) => ({
    attendanceIn: builder.mutation<
      { name: string; employeeId: string },
      { encodedEmployeeId: string }
    >({
      query: (body) => ({
        url: "/attendance-in",
        method: "POST",
        body,
      }),
    }),
    attendanceOut: builder.mutation<
      { name: string; employeeId: string },
      { encodedEmployeeId: string }
    >({
      query: (body) => ({
        url: "/attendance-out",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useAttendanceInMutation, useAttendanceOutMutation } =
  inventoryApi;
