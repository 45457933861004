import { Box, Text, VStack, keyframes } from '@chakra-ui/react';
import { Logo } from './Logo';

const bounce = keyframes`
  50% { transform:translateY(0%); }
  80% { transform:translateY(-25%); }
  90% { transform:translateY(0%); }
  95% { transform:translateY(-7%); }
  97% { transform:translateY(0%); }
  99% { transform:translateY(-3%); }
  100% { transform:translateY(0); }
`
export const Loading = () => {
  return (
    <Box w="100vw" h="100vh" overflow="hidden" display="flex" alignItems="center" justifyContent="center">
      <VStack>
        <Box animation={`${bounce} 2s ease infinite`}>
          <Logo />
        </Box>
        <Text>Loading...</Text>
      </VStack>
    </Box>
  );
};
