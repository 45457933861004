import { DashboardNavigationLayout } from '../../common/Layouts/DashboardNavigationLayout/DashboardNavigationLayout';
import { DashboardView } from '../components/DashboardView';
import { DashboardAuthGateway } from './DashboardAuthGateway';

export const Dashboard = () => {
  return (
    <>
      <DashboardAuthGateway>
        <DashboardNavigationLayout>
          <DashboardView />
        </DashboardNavigationLayout>
      </DashboardAuthGateway>
    </>
  );
};
