import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputLeftAddon,
    InputRightElement,
    Stack,
    Textarea,
} from '@chakra-ui/react';
import { useState } from 'react';
import { FORM_STYLE } from '../../common/constants/styles';

export const AddInstituteForm = () => {
    const [show, setShow] = useState(false);
    const [isFieldMissing, setIsFieldMissing] = useState(false);
    const [name, setName] = useState("");
    const [contact, setContact] = useState("");
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [alternateContact, setAlternateContact] = useState("");
    const [address, setAddress] = useState("");
    const [pinCode, setPinCode] = useState("");
    const [logoUrl, setLogoUrl] = useState("");
    const [totalCreditsRemaining, setTotalCreditsRemaining] = useState(0);
    const [creditsExpiryDate, setCreditsExpiryDate] = useState("");
    const handleClick = () => setShow(!show);
    const handleSubmit = () => {
        setIsFieldMissing(false)
        if (name === "" || contact === "" || password === "" || creditsExpiryDate === "" || totalCreditsRemaining < 0 || address === "" || pinCode === "") {
            setIsFieldMissing(true)
            return;
        }
    };
    return (
        <Box style={{ ...FORM_STYLE }}>
            <Stack spacing={4}>
                <FormControl isRequired>
                    <FormLabel>
                        Name of Institute
                    </FormLabel>
                    <Input isInvalid={isFieldMissing && name === ""} onChange={(e) => setName(e.target.value)} type="text" />
                </FormControl>
                <FormControl isRequired>
                    <FormLabel>Phone Number</FormLabel>
                    <InputGroup>
                        <InputLeftAddon children="+91" />
                        <Input isInvalid={isFieldMissing && contact === ""} onChange={(e) => setContact(e.target.value)} type="tel" placeholder="phone number" />
                    </InputGroup>
                </FormControl>
                <FormControl isRequired>
                    <FormLabel>Password</FormLabel>
                    <InputGroup size="md">
                        <Input isInvalid={isFieldMissing && password === ""} onChange={(e) => setPassword(e.target.value)} pr="4.5rem" type={show ? 'text' : 'password'} placeholder="Enter password" />
                        <InputRightElement width="4.5rem">
                            <Button h="1.75rem" size="sm" onClick={handleClick}>
                                {show ? 'Hide' : 'Show'}
                            </Button>
                        </InputRightElement>
                    </InputGroup>
                </FormControl>
                <FormControl>
                    <FormLabel>Alternate Contact</FormLabel>
                    <InputGroup>
                        <InputLeftAddon children="+91" />
                        <Input onChange={(e) => setAlternateContact(e.target.value)} type="tel" placeholder="alternate phone number" />
                    </InputGroup>
                </FormControl>
                <FormControl isRequired>
                    <FormLabel>Credits to Issue</FormLabel>
                    <InputGroup>
                        <Input value={totalCreditsRemaining} isInvalid={isFieldMissing && totalCreditsRemaining < 0} onChange={(e) => setTotalCreditsRemaining(parseInt(e.target.value))} type="number" placeholder="Credits (Default to 0)" />
                    </InputGroup>
                </FormControl>
                <FormControl isRequired>
                    <FormLabel>
                        Credit Expiry
                    </FormLabel>
                    <Input
                        isInvalid={isFieldMissing && creditsExpiryDate === ""}
                        onChange={(e) => setCreditsExpiryDate(e.target.value)}
                        placeholder="Select Date and Time"
                        type="datetime-local"
                    />
                </FormControl>
                <FormControl>
                    <FormLabel>Email</FormLabel>
                    <Input onChange={(e) => setEmail(e.target.value)} type="email" />
                </FormControl>
                <FormControl>
                    <FormLabel>
                        Logo Url
                    </FormLabel>
                    <Input onChange={(e) => setLogoUrl(e.target.value)} type="text" />
                </FormControl>
                <FormControl isRequired>
                    <FormLabel>
                        Pin Code
                    </FormLabel>
                    <Input isInvalid={isFieldMissing && pinCode === ""} onChange={(e) => setPinCode(e.target.value)} type="postal" />
                </FormControl>
                <FormControl isRequired>
                    <FormLabel>
                        Address
                    </FormLabel>
                    <Textarea isInvalid={isFieldMissing && address === ""} onChange={(e) => setAddress(e.target.value)} />
                </FormControl>
                <Button size="lg" onClick={handleSubmit}>
                    Add Institute
                </Button>
            </Stack>
        </Box>
    );
};
