import { Box, Text } from "@chakra-ui/react"
import { ItemizedInventoryTable } from "./ItemizedInventoryTable"
import { useParams } from "react-router-dom"
import { ItemizedInventoryParentInfo } from "./ItemizedInventoryParentInfo"
import { useState } from "react"
import { CheckoutModal } from "./CheckoutModal"
import { useGetInventoryItemQuery } from "../../inventoryApi"

export const ViewItemizedInventoryView = () => {
    const { inventoryId } = useParams()
    const { data } = useGetInventoryItemQuery({ inventoryId: inventoryId || "" }, { skip: !inventoryId });
    const [isCheckoutModalOpen, setIsCheckoutModalOpen] = useState(false)
    const [itemizedInventoryId, setItemizedInventoryId] = useState("")

    if (!inventoryId) {
        return <Text>Invalid URL</Text>
    }
    const handleCheckout = (id: string) => {
        setItemizedInventoryId(id)
        setIsCheckoutModalOpen(true)
    }

    const onCheckoutModalClose = () => {
        setIsCheckoutModalOpen(false)
    }
    return <Box padding="50px" w="100%" h="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <ItemizedInventoryParentInfo inventoryId={inventoryId} />
        <ItemizedInventoryTable handleCheckout={handleCheckout} inventoryId={inventoryId} />

        <CheckoutModal itemizedInventoryId={itemizedInventoryId} inventoryId={inventoryId} name={data?.name || ""} isOpen={isCheckoutModalOpen} onClose={onCheckoutModalClose} />
    </Box>
}