import { LinkIcon } from "@chakra-ui/icons";
import {
  Box,
  Heading,
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

export const ToolsListWidget = () => {
  return (
    <Box w="100%" h="100%" maxH="300px" overflow="auto">
      <Box bg="white" p="10px">
        <Heading size="md">Tools to Use</Heading>
      </Box>
      <TableContainer w="100%">
        <Table size="sm" variant="striped" colorScheme="teal">
          <Thead>
            <Tr>
              <Th>Sr. No.</Th>
              <Th>Name</Th>
              <Th>Purpose</Th>
              <Th isNumeric>Link</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr bg={"cyan.200"}>
              <Td>1</Td>
              <Td>Zoho Expense</Td>
              <Td>Expense Tracking</Td>
              <Td>
                <IconButton
                  onClick={() =>
                    window.open("https://expense.zoho.in", "_blank")
                  }
                  bg="cyan.400"
                  aria-label="Visit Zoho Expense"
                  icon={<LinkIcon />}
                />
              </Td>
            </Tr>
            <Tr>
              <Td>2</Td>
              <Td>Trell0</Td>
              <Td>Task Management</Td>
              <Td>
                <IconButton
                  onClick={() => window.open("https://trello.com", "_blank")}
                  bg="cyan.400"
                  aria-label="Visit Trello"
                  icon={<LinkIcon />}
                />
              </Td>
            </Tr>
            <Tr bg={"cyan.200"}>
              <Td>3</Td>
              <Td>Roster Share</Td>
              <Td>Roster Generator Tool</Td>
              <Td>
                <IconButton
                  onClick={() =>
                    window.open("https://rostershare.me/", "_blank")
                  }
                  bg="cyan.400"
                  aria-label="Visit Roster Share"
                  icon={<LinkIcon />}
                />
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};
